import { Box, Container } from "@mui/material";
import { ReactNode } from "react";
import { BubbleAnimation } from "../BubbleAnimation";

export const LandingPageBox = ({
  children,
  differentColor = false,
}: {
  children: ReactNode;
  differentColor?: boolean;
}) => {
  return (
    <Box
      alignContent="center"
      alignItems="center"
      justifyContent="center"
      sx={{
        display: "flex",
        py: 2,
        mx: 0,
        minHeight: { md: "100vh" },
        backgroundColor: differentColor ? "primary.main" : "inherit",
      }}
    >
      <Container fixed sx={{ p: 4, zIndex: 100 }}>
        {children}
      </Container>

      <BubbleAnimation inverse={differentColor} />
    </Box>
  );
};
