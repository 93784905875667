import {
  AppBar,
  Avatar,
  Box,
  Button,
  IconButton,
  Stack,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";

import { Footer } from "../components/Footer";
import { Translation } from "../Translation";
import { BubbleAnimation } from "../components/BubbleAnimation";

import "./base.css";

const TemplateAppBar = () => {
  return (
    <AppBar position="static">
      <Toolbar sx={{ zIndex: 100 }}>
        {/*
        <IconButton
          size="large"
          edge="start"
          color="inherit"
          aria-label="menu"
          sx={{ mr: 2 }}
        >
          <MenuIcon />
        </IconButton>
        */}

        <Avatar alt="logo" src="images/logo.png" sx={{ mr: 4 }} />

        <Stack>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Translation id="page.company" />
          </Typography>

          <Typography variant="subtitle1" component="div" sx={{ flexGrow: 1 }}>
            <Translation id="page.slogan" />
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default ({ children }: { children: JSX.Element }) => {
  return (
    <Box>
      <TemplateAppBar />

      {children}

      <Footer />
    </Box>
  );
};
