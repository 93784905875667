import { Typography, Button, Box } from "@mui/material";
import { LandingPageBox } from "./LandingPageBox";
import { Translation } from "../../Translation";

export const LandingPage_Intro = () => {
  return (
    <LandingPageBox>
      <Typography align="center">
        <Box component="img" src="images/logo.png" sx={{ maxWidth: "80%" }} />

        <Typography variant="h4" component="h1" color="primary">
          <Translation id="pages.home.intro.header" />
        </Typography>

        <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
          <Translation id="pages.home.intro.text" />
        </Typography>

        <Button
          variant="contained"
          sx={{ textTransform: "uppercase" }}
          href="#contact"
        >
          <Translation id="pages.home.intro.contactUs" />
        </Button>
      </Typography>
    </LandingPageBox>
  );
};
