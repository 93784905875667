import { Page_Home, Page_Imprint, Page_Legal } from "../pages";

export interface IConfig_Page {
  path: string;
  page: any;
}

export const config_pages: IConfig_Page[] = [
  {
    path: "/",
    page: Page_Home,
  },
  {
    path: "/info",
    page: Page_Imprint,
  },
  {
    path: "/legal",
    page: Page_Legal,
  },
  {
    path: "/privacy",
    page: Page_Legal,
  },
];
