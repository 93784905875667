import { useEffect } from "react";

import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";

import Theme from "./design/theme";
import Template from "./design/template";

import { IConfig_Page, config_pages } from "./config/pages";
import { TranslationManager } from "./Translation";

const PageManager = () => {
  //functions
  const makePageAlias = (
    _pages: IConfig_Page[],
    _id: string,
    _alias: string
  ) => {
    if (!_pages.find((p) => p.path === _alias)) {
      //add base path
      const page = _pages.find((p) => p.path === _id);
      if (!!page) {
        _pages.push({ path: _alias, page: page.page });
      }
    }

    return _pages;
  };

  //check page change to scroll to top
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  //get pages and make "home" & "/" alias
  let pages: IConfig_Page[] = config_pages;
  pages = makePageAlias(pages, "home", "/");
  pages = makePageAlias(pages, "/", "home");
  const pageHome = pages.find((p) => p.path === "home")!;
  const page404 = pages.find((p) => p.path === "404");

  return (
    <Routes>
      {pages.map((p) => (
        <Route
          key={`route_${pages.indexOf(p)}`}
          path={p.path}
          element={<p.page />}
        />
      ))}
      <Route
        path="*"
        element={!!page404 ? <page404.page /> : <pageHome.page />}
      />
    </Routes>
  );
};

const App = () => {
  return (
    <ThemeProvider theme={Theme}>
      <CssBaseline />

      <BrowserRouter>
        <TranslationManager>
          <Template>
            <PageManager />
          </Template>
        </TranslationManager>
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default App;
