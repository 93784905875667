import { Grid, Box, Typography, Divider } from "@mui/material";
import { PageLink } from "./PageLink";
import { Translation } from "../Translation";

export const Social = ({ link, icon }: { link: string; icon: string }) => {
  return (
    <Grid item xs={4} md={1}>
      <Box sx={{ display: "flex" }} justifyContent="center">
        <a href={link} target="_blank">
          <img
            src={icon}
            width="32px"
            height="32px"
            style={{ fill: "f7a91d" }}
          />
        </a>
      </Box>
    </Grid>
  );
};

export const Footer = () => {
  return (
    <Box>
      <Divider sx={{ borderColor: "secondary.main" }} />

      <Box sx={{ my: 4 }}>
        <Typography variant="body2" color="text.secondary" align="center">
          <PageLink to="/info">
            <Translation id="footer.imprint" />
          </PageLink>{" "}
          |{" "}
          <PageLink to="/legal">
            <Translation id="footer.legal" />
          </PageLink>{" "}
          |{" "}
          <PageLink to="/privacy">
            <Translation id="footer.privacy" />
          </PageLink>
        </Typography>
      </Box>

      <Grid container justifyContent="center">
        <Social
          icon="./images/socials/telegram.svg"
          link="https://t.me/Loesil"
        />
        <Social
          icon="./images/socials/discord.svg"
          link="https://discordapp.com/users/1080838375008915526"
        />
        <Social
          icon="./images/socials/github.svg"
          link="https://github.com/MoonLabsDev"
        />
      </Grid>
    </Box>
  );
};
