import Link, { LinkProps } from "@mui/material/Link";
import { NavLink } from "react-router-dom";

export interface PageLinkProps extends LinkProps {
  to: string;
}

export const PageLink = (props: PageLinkProps) => {
  return (
    <NavLink to={props.to}>
      <Link {...props}>{props.children}</Link>
    </NavLink>
  );
};
