import {
  Box,
  Typography,
  Grid,
  Stack,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import { LandingPageBox } from "./LandingPageBox";

const CustomCard = ({
  children,
  title,
}: {
  children: JSX.Element | string;
  title: string;
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <Paper elevation={7} sx={{ height: "100%" }}>
        <Card variant="outlined">
          <CardContent>
            <Typography
              variant="h6"
              align="center"
              color="primary"
              sx={{ mb: 2 }}
            >
              {title}
            </Typography>

            <Typography align="center">{children}</Typography>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  );
};

export const LandingPage_Services = () => {
  return (
    <LandingPageBox differentColor={true}>
      <Box>
        <Stack>
          <Typography variant="h3" component="h2" align="center">
            Our Services
          </Typography>

          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            Top-notch Blockchain Service
          </Typography>
        </Stack>

        <Grid container spacing={2} justifyContent="center">
          <CustomCard title="Smart Contracts">
            We develop high quality Smart Contracts, NFTs, ReflectTokens,
            DividendTokens, Staking and more.​
          </CustomCard>

          <CustomCard title="dApps">
            Flexible dApps, fully tailored to your needs. Dashboards, NFT
            minting, Staking and more.
          </CustomCard>

          <CustomCard title="Consulting">
            Advices for Tokenomics, Smart Contracts, dApps and everything
            Blockchain-related.
          </CustomCard>

          <CustomCard title="Code Review">
            To help you ensure that you have secure & clean code, we help you
            review and refactor your code.
          </CustomCard>

          <CustomCard title="Bots">
            Custom tailored Bots for Discord, Telegram or to interact with CEX
            or Blockchains.
          </CustomCard>
        </Grid>
      </Box>
    </LandingPageBox>
  );
};
