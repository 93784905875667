import { Link, Box, Typography, Container, Grid } from "@mui/material";

const Contact = () => {
  return (
    <Container>
      <Typography fontWeight={700}>
        Moon Labs UG (haftungsbeschr&auml;nkt)
      </Typography>
      <Typography>
        Oesterleystr. 7
        <br />
        37083 Göttingen
        <br />
        <br />
        Phone: <Link href="tel:+491708366182">+49 170 8366182</Link>
        <br />
        E-Mail: <Link href="mailto:info@moon-labs.io">info@moon-labs.io</Link>
      </Typography>
    </Container>
  );
};

const Data = () => {
  return (
    <Container>
      <Typography fontWeight={700}>Managing Directors</Typography>
      <Typography>Nico Rindermann</Typography>
      <br />
      <Typography fontWeight={700}>Commercial register</Typography>
      <Typography>G&ouml;ttingen HRB 207171</Typography>
      <br />
      <Typography fontWeight={700}>VAT ID</Typography>
      <Typography>DE 364514293</Typography>
    </Container>
  );
};

export default () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Contact />
          </Grid>
          <Grid item xs={12} md={6}>
            <Data />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};
