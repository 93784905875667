import { Box } from "@mui/material";
import { LandingPage_Intro } from "../components/landingPage/Intro";
import { LandingPage_Services } from "../components/landingPage/Services";
import { LandingPage_Technology } from "../components/landingPage/Technology";
import { LandingPage_Contact } from "../components/landingPage/Contact";

export default () => {
  return (
    <Box sx={{ px: 0 }}>
      <LandingPage_Intro />
      <LandingPage_Services />
      <LandingPage_Technology />
      <LandingPage_Contact />
    </Box>
  );
};
