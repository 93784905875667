import { Box, Typography, Container } from "@mui/material";

export default () => {
  return (
    <Container maxWidth="md">
      <Box sx={{ my: 4 }}>
        <Typography variant="h5" fontWeight={700}>
          Legal note | data protection
        </Typography>
        <Typography>
          Thank you for your interests in our website. The protection of your
          privacy has considerable importance for us. Below we would like to
          inform you in detail about the handling of your personal data on our
          website in accordance with Art. 13 of the General Data Protection
          Regulation (GDPR).
        </Typography>
        <br />
        <Typography color="primary" fontWeight={700}>
          A. Data processing while visiting our website
        </Typography>
        <br />
        <Typography fontWeight={700}>I. Responsible</Typography>
        <br />
        <Typography>
          Responsible for the following data collection an processing is
          <br />
          <br />
          Moon Labs UG (haftungsbeschr&auml;nkt)
          <br />
          Oesterleystr. 7
          <br />
          37083 Göttingen
          <br />
          Tel.: +49 170 8366182
          <br />
          E-Mail: info@moon-labs.io
          <br />
          https://moon-labs.io
        </Typography>
        <br />
        <Typography fontWeight={700}>II. Storage of your IP address</Typography>
        <br />
        <Typography>
          We store the IP adress transmitted by your web browser strictly for
          the purpose of 7 days in the interest of being able to recognise,
          limit and eliminate attacks on our website. After this period of time,
          we delete or anonymise the IP adress. The legal basis for the data
          processing is Art. 6 para. 1 p. 1 lit. f) GDPR.
        </Typography>
        <br />
        <Typography fontWeight={700}>III. Usage data</Typography>
        <br />
        <Typography>
          When you visit our website, our webserver temporarily evaluates
          so-called usage data for statistical purposes in order to improve the
          quality of our website. This data consists of the following data
          categories:
          <ul>
            <li>the page from which the file was requested,</li>
            <li>the name of the file,</li>
            <li>the date and the time of the query,</li>
            <li>the amount of data transferred,</li>
            <li>the operating system you are using,</li>
            <li>the referrer URL (the previously visited website),</li>
            <li>the requesting provider,</li>
            <li>the description of the type of the used web browser,</li>
            <li>the IP adress of the requesting computer.</li>
          </ul>
        </Typography>
        <br />
        <Typography fontWeight={700}>IV. Data Security</Typography>
        <br />
        <Typography>
          In order to protect your data as comprehensively as possible from
          unwanted access, we implement technial and organisational measures.
          These measures inclued encryption procedures on our website. Your data
          is transferred from your computer to our server and vice versa via the
          internet using TLS encryption. You can usually recognise this by the
          fact that the lock symbol is closed in the status bar of your browser
          and the adress line begins with https://.
        </Typography>
        <br />
        <Typography fontWeight={700}>V. Processor</Typography>
        <br />
        <Typography>
          We use external service providers as commissioned processors in
          accordance with Art. 28 GDPR, who support us in the operation of our
          website and the associated processes. Our service providers are
          strictly bound by instructions and contractually obligated to us
          accordingly. We use the following providers:
          <br />
          <br />
          IONOS SE Büro Berlin
          <br />
          Revaler Str. 30
          <br />
          10245 Berlin
          <br />
          Germany
        </Typography>
        <br />
        <Typography fontWeight={700}>VI. Cookies</Typography>
        <br />
        <Typography>
          {/*
          We use cookies on our website. Cookies are small texte
          files that can be stored on and extracted from your device. There is a
          difference between session cookies, which are deleted as soon as you
          close your browser, and permanent cookies, which are stored for more
          than the duration of the session. Cookies can contain data that make
          it possible to restore the used device. In some cases, however,
          cookies only contain information on certain settings that cannot be
          related to a person. We use technically necessary cookies for display
          and funtion of our website. The processing is based on Art. 6 para. 1
          p. 1 lit. f) GDPR. Our interest is to enable the presentation of our
          website. You can object to the processing at any time. To do so,
          please click on the following link. You can set your browser to inform
          you about the placement of cookies. In addition, you can delete
          cookies at any time via the corresponding browser setting and prevent
          the setting of new cookies. In this specific case, our website may not
          be displayed optimally and some functions may no longer be technically
          available. We also use tracking cookies on our website to track your
          user behaviour and subsequently send you targeted advertising. The
          processing is based on your consent accoring to Art. 6 para. 1 p. 1
          lit. a) GDPR, if you have previously given your consent for tracking
          via our cookie banner. We use the following tracking tools Withdrawal
          of your consent for tracking You can revoke your consent for tracking
          at any time with the effect for the future without affecting the
          lawfulness of the previous processing. To issue the revocation, please
          click on the following link.
          */}
          We don't use any Cookies on our website.
        </Typography>
        <br />
        <Typography fontWeight={700}>VII. Contacting</Typography>
        <br />
        <Typography>
          You have the option of contacting us via E-Mail or any other channel.
          In doing so, we process your title, first and last name, business
          e-mail, company. Unfortunately we cannot process your request without
          this information. The data processing is based on our legitimate
          interest pursuant to Art. 6 para. 1 p. 1 lit. f) GDPR of answering
          your enquiry in the most uncomplicated, timley and customer-oriented
          manner as possible. You have the right to object to the data
          processing at any time at info@moon-labs.io . However, then we will be
          no longer able to process your request. Enquiries that you send us as
          a part of a general enquiry about the services and products we offer
          are processed on the basis of Art. 6 para. 1 p. 1 lit. b) GDPR. Your
          data will be processed exclusively for the purpose of answering your
          enquiry and deleted as soon as the storage is no longer necessary and
          no legal or contractual retention periods exist to the contrary. Your
          data will not be passed on to third parties.
        </Typography>
        <br />
        <Typography fontWeight={700}>VIII. embedded YouTube videos</Typography>
        <br />
        <Typography>
          We embed a YouTube video on a subpage of our website. When this
          subpage is accessed, content from YouTube is loaded. In this context,
          YouTube also receives your IP address, which is technically required
          to retrieve the content. In principle, we have no influence on further
          processing by YouTube. However, when embedding the videos, we have
          taken care to activate the extended data protection mode offered by
          YouTube.
        </Typography>
        <br />
        <Typography color="primary" fontWeight={700}>
          B. Information on data processing for business partners and suppliers
        </Typography>
        <br />
        <Typography>Purpose and legal basis for data processing</Typography>
        <br />
        <Typography fontWeight={700}>I. Fulfillment of contract</Typography>
        <br />
        <Typography>
          If a contract between Moon Labs and you as a natural person is in the
          offing or comes into being, Moon Labs will process your personal data
          such as first and last name as well as your title, e-mail address,
          company, industry and postal code as part of the business relationship
          with you as our customers, business partners and interested parties.
          We base this data processing on the legal basis according to Art. 6
          para. 1 p. 1 lit. b) GDPR, as this is necessary for the fulfillment of
          the contract with you. In the event that a contract is concluded
          between Moon Labs and your company as a legal entity, we process your
          above-mentioned personal data of the contact persons on the legal
          basis of Art. 6 para. 1 p. 1 lit. f) GDPR. Our legitimate interest is
          to contact you as a contact person and to be able to fulfill the
          contract with the legal entity.
        </Typography>
        <br />
        <Typography fontWeight={700}>
          II. Information about goods and services
        </Typography>
        <br />
        <Typography>
          <ul>
            <li>
              1. In order to keep you informed about our goods and services, we
              will send you postal advertising. The legal basis for processing
              your contact data is Art. 6 para. 1 p. 1 lit. f) GDPR. Our
              legitimate interest is to keep you up to date regarding our offer.
              You have the option to object to the data processing at
              info@moon-labs.io at any time. We will then no longer send you any
              advertising by mail.
            </li>
            <li>
              2. We are also happy to keep you informed about our goods and
              services by telephone. If the telephone number of the company is a
              legal entity, we base the data processing on the (presumed)
              consent pursuant to Art. 6 para. 1 p. 1 lit. a) GDPR, § 7 para. 2
              no. 1 alt. 2 of the Act against Unfair Competition.
            </li>
            <li>
              3. We use your e-mail address for advertising purposes on the
              basis of your consent pursuant to Art. 6 para. 1 p. 1 lit. a)
              GDPR, § 7 para. 2 No. 2 of the Act against Unfair Competition.
            </li>
          </ul>
          You have the option to revoke your consent at any time at
          info@moon-labs.io . Under the narrow conditions of § 7 para. 3 of the
          Act against Unfair Competition, we will also send you our electronic
          advertising without your previously granted consent. In this case, you
          have the right to object to the data processing at any time under
          info@moon-labs.io . Moon Labs stores the personal data provided by you
          for the duration of the business relationship with you and until the
          expiry of the applicable limitation periods, as well as any resulting
          claims and statutory retention obligations.
        </Typography>
        <br />
        <Typography color="primary" fontWeight={700}>
          C. Your rights as a data subject
        </Typography>
        <br />
        <Typography>
          When processing your personal data, the GDPR grants you certain rights
          as a data subject:
        </Typography>
        <br />
        <Typography fontWeight={700}>
          1. Right of access (Art. 15 GDPR)
        </Typography>
        <br />
        <Typography>
          You have the right to request confirmation as to whether personal data
          concerning you are beign processed; if this is the case, you have the
          right to be informed about this personal data and to receive the
          information listed in detail in Article 15 of the GDPR.
        </Typography>
        <br />
        <Typography fontWeight={700}>
          2. Right to rectification and erasure (Art. 16 and Art. 17 GDPR)
        </Typography>
        <br />
        <Typography>
          You have the right to request without delay the correction of any
          inaccurate personal data concering you and where applicable the
          completion of any incomplete personal data. You also have the right to
          request that personal data relating to you will be deleted without
          delay if one of the resons which are listed in Art. 17 of the GDPR
          applies, e.g. if the data is no longer required for the purposes
          pursued.
        </Typography>
        <br />
        <Typography fontWeight={700}>
          3. Right of restriction of processing (Art. 18 GDPR)
        </Typography>
        <br />
        <Typography>
          You have the right to request the restriction of processing if one of
          the conditions which are listed in Article 18 of the GDPR applies,
          e.g. if you have objected to the processing pursuant to Article 21 of
          the GDPR or for the duration of any review as to whether our
          legitimate interests outweigh you interests as a data subject.
        </Typography>
        <br />
        <Typography fontWeight={700}>
          4. Right to data portability (Art. 20 GDPR)
        </Typography>
        <br />
        <Typography>
          In certain cases, which are listed in detail in Art. 20 of the GDPR,
          you have the right to receive the personal data concering you in a
          structured, common and machine-readable format or to request the
          transfer of this data to a third party.
        </Typography>
        <br />
        <Typography fontWeight={700}>
          5. Right to object (Art. 21 GDPR)
        </Typography>
        <br />
        <Typography>
          If data is collected on the basis of Art. 6 para. 1 p. 1 lit. f) GDPR
          (data processing for the protection of legitimate interests), you have
          the right to object to the processing at any time for reasons arising
          from your particular situation. We will then no longer process the
          personal data unless there are demonstrably compelling legitimate
          reasons for the processing which override your interests, rights and
          freedom, or the processing serves to assert, exercise or defend legal
          claims.
        </Typography>
        <br />
        <Typography fontWeight={700}>
          6. Right of withdrawal (Art. 7 para. 3 GDPR)
        </Typography>
        <br />
        <Typography>
          If the data processing is based on your consent, you can revoke your
          consent at any time without the revocation affecting the lawfulness of
          the data processing up tot he time oft he revocation.
        </Typography>
        <br />
        <Typography fontWeight={700}>
          7. Right of appeal to the supervisory authority
        </Typography>
        <br />
        <Typography>
          Pursuant to Art. 77 of the GDPR, you also have the right to lodge a
          complaint with the superviory authority if you considering that the
          processing of the data concering you violates data protection
          provisions. The right of complaint can be asserted in particular with
          the supervisory authority in the member state of your place of
          residence, your place of work or the place of the alleged
          infringement.
        </Typography>
        <br />
        <Typography color="primary" fontWeight={700}>
          D. Contact details of our data protection officer
        </Typography>
        <br />
        <Typography>
          Our data protection officer will be happy to provide you with
          information on the subject of data protection. You can reach our data
          protection officer via info@moon-labs.io .
        </Typography>
      </Box>
    </Container>
  );
};
