import {
  Box,
  Typography,
  Grid,
  Stack,
  Link,
  Paper,
  Card,
  CardContent,
} from "@mui/material";
import { LandingPageBox } from "./LandingPageBox";

const CustomCard = ({
  children,
  title,
}: {
  children: JSX.Element | string;
  title: string;
}) => {
  return (
    <Grid item xs={12} sm={6}>
      <Paper elevation={7} sx={{ height: "100%" }}>
        <Card variant="outlined">
          <CardContent>
            <Typography variant="h6" align="center" sx={{ mb: 2 }}>
              {title}
            </Typography>

            <Typography align="center">{children}</Typography>
          </CardContent>
        </Card>
      </Paper>
    </Grid>
  );
};

export const LandingPage_Contact = () => {
  return (
    <LandingPageBox differentColor={true}>
      <Box>
        <Stack>
          <Typography variant="h3" component="h2" align="center">
            Contact
          </Typography>

          <Typography variant="h6" align="center" sx={{ mb: 2 }}>
            Fast and qualified response to your inquiry
          </Typography>

          <div id="contact" />

          <Grid container spacing={2} justifyContent="center" sx={{ my: 2 }}>
            <CustomCard title="E-Mail:">
              <Link href="mailto://info@moon-labs.io">info@moon-labs.io</Link>
            </CustomCard>
            <CustomCard title="Phone:">
              <Link href="tel:+49170836618">+49 170 88366182</Link>
            </CustomCard>

            <CustomCard title="Telegram:">
              <Link href="https://t.me/Loesil">Loesil</Link>
            </CustomCard>
            <CustomCard title="Discord:">
              <Link href="https://discordapp.com/users/1080838375008915526">
                _moonlabs_
              </Link>
            </CustomCard>
            <CustomCard title="GitHub:">
              <Link href="https://github.com/MoonLabsDev">MoonLabsDev</Link>
            </CustomCard>
          </Grid>
        </Stack>
      </Box>
    </LandingPageBox>
  );
};
