import { Box } from "@mui/material";
import { ReactNode, useState } from "react";

const randRange = (from: number, to: number, precision: number) => {
  const range = to - from;
  const r = Math.random() * range + from;
  const ret =
    precision === 0 ? Math.floor(r) : parseFloat(r.toFixed(precision));
  console.log(ret);
  return ret;
};

const Bubble = ({ n, inverse }: { n: number; inverse: boolean }) => {
  const [size] = useState(() => randRange(1, 7, 0));
  const [duration] = useState(() => randRange(25, 55, 2));
  const [delay] = useState(() => randRange(0, 30, 2));
  const [mx1] = useState(() => randRange(5, 95, 0));
  const [mx2] = useState(() => randRange(5, 95, 0));
  const [my1] = useState(() => randRange(105, 130, 0));
  const [my2] = useState(() => randRange(-105, -130, 0));
  const [scale] = useState(() => randRange(2, 5, 0));

  return (
    <Box
      sx={{
        [`@keyframes moveBubbles_${n}`]: {
          "0%": {
            transform: `translate3d(${mx1}vw, ${my1}vh, 0)`,
          },
          to: {
            transform: `translate3d(${mx2}vw, ${my2}vh, 0)`,
          },
        },
        opacity: 0.5,
        position: "absolute",
        transform: "translateY(-10vh)",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
        width: `${size}px`,
        height: `${size}px`,
        animationDelay: `${delay}s`,
        animationDuration: `${duration}s`,
        animationName: `moveBubbles_${n}`,
      }}
    >
      <Box
        sx={{
          "@keyframes scaleBubbles": {
            "0%": {
              transform: "scale3d(.4, .4, 1)",
            },
            "50%": {
              transform: "scale3d(2.2, 2.2, 1)",
            },
            "100%": {
              transform: "scale3d(.4, .4, 1)",
            },
          },
          width: "100%",
          height: "100%",
          borderRadius: "50%",
          mixBlendMode: "screen",
          border: `1px solid #${inverse ? "000" : "fff"}`,
          animationDelay: `${delay}s`,
          animation: `scaleBubbles ${scale}s infinite`,
        }}
      ></Box>
    </Box>
  );
};

const Bubbles = ({ inverse }: { inverse: boolean }) => {
  const ret: ReactNode[] = [];
  for (let n = 0; n < 30; n++) {
    ret.push(<Bubble n={n} inverse={inverse} />);
  }
  return <>{ret}</>;
};

export const BubbleAnimation = ({ inverse }: { inverse: boolean }) => {
  return (
    <Box
      sx={{
        opacity: 0.9,
        overflow: "hidden",
        width: "100%",
        height: "100vh",
        position: "absolute",
        zIndex: 10,
      }}
    >
      <Bubbles inverse={inverse} />
    </Box>
  );
};
