import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#f7a91d",
      contrastText: "#f5f5f5",
    },
    secondary: {
      main: "#f7a91d",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#000000",
      paper: "#000000",
    },
    text: {
      primary: "#f5f5f5",
      secondary: "#dddddd",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        rounded: { borderRadius: 15 },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
  },
});

export default theme;
