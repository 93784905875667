import { Box, Grid, Stack, Typography } from "@mui/material";
import { LandingPageBox } from "./LandingPageBox";
import { Translation } from "../../Translation";

const technologies = {
  blockchains: [
    {
      title: "Ethereum",
      icon: "images/technology/ethereum.svg",
    },
    {
      title: "Binance",
      icon: "images/technology/binance.svg",
    },
    {
      title: "Polygon",
      icon: "images/technology/polygon.svg",
    },
    {
      title: "Fantom",
      icon: "images/technology/fantom.svg",
    },
    {
      title: "Arbitrum",
      icon: "images/technology/arbitrum.svg",
    },
    {
      title: "Avalanche",
      icon: "images/technology/avalanche.svg",
    },
  ],
  smartContracts: [
    {
      title: "Solidity",
      icon: "images/technology/solidity.svg",
    },
  ],
  frontEnd: [
    {
      title: "TypeScript",
      icon: "images/technology/ts.svg",
    },
    {
      title: "React",
      icon: "images/technology/react.svg",
    },
  ],
  backEnd: [
    {
      title: "Node.JS",
      icon: "images/technology/nodejs.svg",
    },
    {
      title: "C#",
      icon: "images/technology/c_sharp.svg",
    },
  ],
};

const Grid_Item = ({
  title,
  icon,
  xs = 1,
  sm = 1,
  md = 1,
}: {
  title: string;
  icon: string;
  xs?: number;
  sm?: number;
  md?: number;
}) => {
  return (
    <Grid item xs={xs} md={md} sx={{ minWidth: "100px" }}>
      <Stack justifyContent="center" alignItems="center">
        <img width={64} height={64} src={icon} alt={title} />

        <Typography align="center">{title}</Typography>
      </Stack>
    </Grid>
  );
};

const GridBlockchains = () => {
  return (
    <GridTemplate
      md={6}
      mdChild={4}
      title="Blockchains"
      items={technologies.blockchains}
    />
  );
};

const GridSmartContracts = () => {
  return (
    <GridTemplate title="Smart Contracts" items={technologies.smartContracts} />
  );
};

const GridFrontend = () => {
  return <GridTemplate title="Frontend" items={technologies.frontEnd} />;
};

const GridBackend = () => {
  return <GridTemplate title="Backend" items={technologies.backEnd} />;
};

const GridTemplate = ({
  md = 2,
  mdChild = 12,
  title,
  items,
}: {
  md?: number;
  mdChild?: number;
  title: string;
  items: any;
}) => {
  return (
    <Grid item xs={12} md={md}>
      <Typography align="center" fontWeight={700} sx={{ mb: 2 }}>
        {title}
      </Typography>
      <Grid container spacing={2} justifyContent="center">
        {items.map((i: any) => (
          <Grid_Item xs={4} md={mdChild} title={i.title} icon={i.icon} />
        ))}
      </Grid>
    </Grid>
  );
};

export const LandingPage_Technology = () => {
  return (
    <LandingPageBox>
      <Box>
        <Typography variant="h4" component="h2" align="center" color="primary">
          <Translation id="pages.home.technology.header" />
        </Typography>

        <Typography
          variant="h6"
          color="text.secondary"
          align="center"
          sx={{ mb: 2 }}
        >
          <Translation id="pages.home.technology.text" />
        </Typography>

        <Grid container spacing={4}>
          <GridBlockchains />
          <GridSmartContracts />
          <GridFrontend />
          <GridBackend />
        </Grid>
      </Box>
    </LandingPageBox>
  );
};
